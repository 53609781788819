import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCU3zHDC-xsYztlpUsqmBcZHPIlG_5lyPE",
    authDomain: "kodin-pro.firebaseapp.com",
    databaseURL: "https://kodin-pro.firebaseio.com",
    projectId: "kodin-pro",
    storageBucket: "kodin-pro.appspot.com",
    messagingSenderId: "943112504040",
    appId: "1:943112504040:web:8d697c9f51582693933f5d",
    measurementId: "G-XVX1G684SM"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {
    app,
    analytics
};
