import Vue from "vue";
import Vuex from "vuex";
import Framework7 from "framework7/framework7.esm.bundle.js";
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";
import "framework7/css/framework7.css";
import {SAPlatform, Core} from "@sa-platform/sa-p-core";
import App from "./app";
import "material-design-icons/iconfont/material-icons.css";
import "framework7-icons/css/framework7-icons.css";
import "./css/app.scss";
import Area51 from "./templates/area51.vue";
import Area52 from "./templates/area52.vue";
import Page from "@sa-platform/sa-t-page/sa-t-page.vue";
import SessionEvents from "@sa-platform/sa-p-core/session-events.js";
import "./filters";
import {store} from "./store/store.js";
import * as VueGoogleMaps from "vue2-google-maps";

var app;

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyCTM113jJAKAA5Ugh9gSp3bRpghT7AXq44",
        libraries: "places",
        region: "FI",
        language: "fi"
    }
});

SAPlatform.startApp({version: "1.0.0", Vue}, function(data) {
    //let's get routes
    var routes = data.routes;

    /*
     * Let's add some own custom routes (totally optional)
     * Never add custom routes to production build (unless very good reason)
     */
    routes.push({path: "/preview/", component: Page});
    routes.push({path: "/area51/", component: Area51});
    routes.push({path: "/area51/:routePl/", component: Area51});
    if(window.SAPlatform.server.disable) {
        routes.push({path: "/", component: Area51});
    }


    window.routes = routes;

    //Initing VueX
    Vue.use(Vuex);

    Vue.filter("currency", function(value) {
        if(typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat("fi-FI", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    });
    // Init F7 Vue Plugin
    Framework7.use(Framework7Vue);


    // Init App
    app = new Vue({
        components: {
            app: App
        },
        el: "#app",
        pushState: true,
        store,
        template: "<app/>"
    });
    SessionEvents.init(app);
}.bind(this));
