import {analytics} from "./firebase";
import {setCurrentScreen, logEvent} from "firebase/analytics";

const PAGE_ID_PATTERN = /\/(\d+)\//;

function access(obj, path) {
    const parts = path.split(".");

    let cur = obj;
    for(const part of parts) {
        cur = cur[part];
        if(cur === undefined) return;
    }

    return cur;
}

export function log(event) {
    console.info("Logging event", event);
    logEvent(analytics, event);
}

export function logScreen(page) {
    const path = access(page, "route.route.path");
    const res = PAGE_ID_PATTERN.exec(path);
    if(!res) {
        console.warn("Unable to get page id from path", path);
        return;
    }

    const id = parseInt(res[1]);
    try {
        const wpPage = window.SAPlatform.stores.SAPlatformStore.getters["content/getPost"](id);
        const pageTitle = access(wpPage, "translations.universal.title");
        if(pageTitle === null) throw Error("Failed to get page title");
        console.info("Screen name:", pageTitle);
        setCurrentScreen(analytics, pageTitle);
        logEvent(analytics, "screen_view");
    } catch(e) {
        console.warn("Failed to fetch wp page info", e);
    }
}
