import {genUUID} from "@/utils";

async function deviceReady() {
    return new Promise(resolve => {
        document.addEventListener("deviceready", resolve, {once: true});
    });
}

function getCordovaDeviceID() {
    if(window.plugins && window.plugins.uniqueDeviceID) {
        return new Promise(resolve => window.plugins.uniqueDeviceID.get(uuid => resolve(uuid)));
    }

    return getBrowserID();
}

async function getBrowserID() {
    let id = localStorage.getItem("device-uuid");
    if(id === null) {
        id = genUUID();
        localStorage.setItem("device-uuid", id);
    }

    return id;
}

export async function getDeviceUUID() {
    if("cordova" in window) {
        await deviceReady();
        return getCordovaDeviceID();
    }

    return null;
}
