import {store} from "./store/store.js";

export function generateSetterGetter(stateName, module) {
    return {
        get() {
            return module ? store.state[module][stateName] : store.state[stateName];
        },
        set(value) {
            store.commit("setState", {stateName, value});
        }
    };
}

/**
 *
 * @param {Array} a
 * @param {Array} b
 */
export function deepEqualArrPrimitive(a, b) {
    if(a.length !== b.length) return false;

    for(let i = 0; i < a.length; i++) {
        const av = a[i];
        const bv = b[i];

        if(av !== bv) return false;
    }

    return true;
}

export function distanceBetweenPointsMeters(pos1, pos2) {
    const lat1 = pos1.lat;
    const lon1 = pos1.lng;
    const lat2 = pos2.lat;
    const lon2 = pos2.lng;

    const R = 6371e3; // Earth's radius in meters
    const φ1 = lat1 * Math.PI / 180; // convert lat1 to radians
    const φ2 = lat2 * Math.PI / 180; // convert lat2 to radians
    const Δφ = (lat2 - lat1) * Math.PI / 180; // difference of latitudes in radians
    const Δλ = (lon2 - lon1) * Math.PI / 180; // difference of longitudes in radians

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance;
}

export function distanceBetweenPoints(pos1, pos2) {
    const radlat1 = Math.PI * pos1.lat / 180;
    const radlat2 = Math.PI * pos2.lat / 180;
    const theta = pos1.lng - pos2.lng;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;

    return dist;
}


const byteLookup = Array(256).fill(0)
    .map((_, index) => index.toString(16).padStart(2, "0"));

/**
 *
 * @param {Uint8Array} buff
 * @param {number} ind
 * @param {number} len
 * @returns
 */
function byteStr(buff, ind, len) {
    const slice = buff.slice(ind, ind + len);
    return Array.from(slice).map(b => byteLookup[b])
        .join("");
}

export function genUUID() {
    const buff = new Uint8Array(16);
    window.crypto.getRandomValues(buff);

    buff[6] |= 0b01000000;
    buff[6] &= ~0b10110000;

    buff[8] |= 0b10000000;
    buff[8] &= ~0b01000000;

    const parts = [
        byteStr(buff, 0, 4),
        "-",
        byteStr(buff, 4, 2),
        "-",
        byteStr(buff, 6, 2),
        "-",
        byteStr(buff, 8, 2),
        "-",
        byteStr(buff, 10, 6),
    ];

    return parts.join("");
}
